.HeaderText {
  font-weight: 700;
  flex-grow: 1;
  display: flex;
  align-items: center;
  color: var(--mb-color-text-dark);

  &.HeaderTextCentered {
    justify-content: center;
  }
}

.HeaderTextContent {
  overflow: hidden;
  text-overflow: ellipsis;
}

.HeaderTextContainer {
  flex-grow: 1;
  overflow: hidden;

  &.clickable:hover > * {
    color: var(--mb-color-brand);
    cursor: pointer;
  }
}

.HeaderContainer {
  flex-shrink: 0;
}
