/* This class should be set to the ECharts tooltip root element via option to override ECharts defaults */
.ChartTooltipRoot {
  font: unset !important;
  background-color: var(--mb-color-tooltip-background) !important;
  border: none !important;
  padding: 0 !important;
  color: var(--mb-color-tooltip-text) !important;
}

.Table {
  min-width: 240px;
  width: 100%;
  border-collapse: collapse;
}

.TableWrapper {
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  margin: 0.25rem 0 0.875rem;
}

.Header {
  position: sticky;
  top: 0;
  margin: 0.75rem 1rem 0.25rem;
  font-weight: 400;
  font-size: 13px;
  color: var(--mb-color-tooltip-text-secondary);
  background: var(--mb-color-tooltip-background);
  z-index: 1;
}

.TableNoHeader {
  margin-top: 1rem;
}

.TableBody {
  width: 100%;
}

.Row {
  font-size: 14px;
}

.SecondaryRow {
  color: var(--mb-color-tooltip-text-secondary);
  font-size: 12px;
}

.RowFocused {
  background: var(--mb-color-tooltip-background-focused);
}

.Cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.25rem 0;
  vertical-align: middle;
  text-align: right;
  font-weight: 600;
  width: auto;
}

.Cell + .Cell:not(.NameCell) {
  padding-left: 0.75rem;
}

.Cell:first-of-type {
  padding-left: 1rem;
}

.Cell:last-of-type {
  padding-right: 1rem;
}

.NameCell {
  padding-left: 0.375rem;
  font-weight: 400;
  text-align: left;
}

.MainValueCell,
.SecondaryValueCell,
.IndicatorCell {
  width: 1px;
}

.SecondaryValueCell {
  font-weight: 600;
  color: var(--mb-color-tooltip-text-secondary);
}

.IndicatorCell {
  line-height: 12px;
  text-align: center;
}

.Indicator {
  border-radius: 100%;
  width: 12px;
  height: 12px;
  display: inline-block;
}

.FooterRow {
  position: sticky;
  bottom: 0;
  background: var(--mb-color-tooltip-background);
  z-index: 1;
}

.FooterRow .Cell {
  padding-top: 0.75rem;
  position: relative;
}

.SecondaryRow .NameCell {
  color: color-mix(in srgb, #fff 90%, var(--mb-color-tooltip-background));
}
